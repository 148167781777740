import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import styled from "styled-components";

const TableWrapper = styled.div`
  .rdt_TableHead .rdt_TableCol {
    font-weight: bold;
  }
`;

const TableList = ({
  columns,
  data,
  props
}) => {
  return (
    <TableWrapper {...props}>
      <DataTable
        columns={columns}
        data={data}
        pagination={true}
      />
    </TableWrapper>
  );
}

TableList.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};

TableList.defaultProps = {
  columns: [],
  data: []
};

export default TableList;
