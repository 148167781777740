import countryJson from "countrycitystatejson";

export const getLocalStorageUser = () => {
  let user = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    user = currentUser;
  }

  return user;
}

export const getAlias = (valueAlias) => {
  let alias = "";
  if (valueAlias) {
    valueAlias.map((name, index) => {
      if (index === valueAlias.length - 1)
        alias += name;
      else
        alias += `${name}, `;
      
      return false;
    });
  }

  return alias;
}

export const countries = countryJson.getCountries().sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

export const getStates = (code) => {
  if (code)
    return countryJson.getStatesByShort(code);

  return [];
}

export const getCities = (code, state) => {
  if (code && state)
    return countryJson.getCities(code, state);

  return [];
}