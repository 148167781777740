import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from 'pages/user/login';
import PageDashboard from 'pages/user/dashboard';

import ProtectedRoute from "./protected.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />

        <ProtectedRoute exact path="/" role="user" component={PageDashboard} />
      </Switch>
    </Router>
  );
}

export default AppRouter;