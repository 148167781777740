import React from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';

import { countries, getStates } from "utils/common";

// ----------------------------------------------------------------------

const CorporateForm = ({
  initialValues,
  onSubmit
}) => {
  const UserSchema = Yup.object().shape({
    corpName: Yup.string().required('Corporate Name is required'),
    uen: Yup.string().required('UEN is required'),
    dateOfIncorporation: Yup.string().required('Date Of Incorporation is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const optionCountries = countries.map((country) => (
    {
      name: country.name,
      value: country.shortName
    }
  ));

  const getOptionStates = () => {
    const options = getStates(getFieldProps("country").value);

    return options.map((value) => (
      {
        name: value,
        value: value
      }
    ))
  }

  const addAddress = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("address").value.length, "");
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Corporate name"
              {...getFieldProps('corpName')}
              error={Boolean(touched.corpName && errors.corpName)}
              helpertext={touched.corpName && errors.corpName}
            />

            <TextField
              fullWidth
              label="Alias"
              {...getFieldProps('alias')}
              error={Boolean(touched.alias && errors.alias)}
              helpertext={touched.alias && errors.alias}
            />

            <TextField
              fullWidth
              label="UEN"
              {...getFieldProps('uen')}
              error={Boolean(touched.uen && errors.uen)}
              helpertext={touched.uen && errors.uen}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="dateOfIncorporation"
              label="Date Of Incorporation"
              type="date"
              {...getFieldProps('dateOfIncorporation')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="Contact No"
              {...getFieldProps('contactNo')}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helpertext={touched.contactNo && errors.contactNo}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Country</InputLabel>
              <Select
                label="Country"
                {...getFieldProps('country')}
                error={Boolean(touched.country && errors.country)}
                helpertext={touched.country && errors.country}
              >
                {
                  optionCountries.map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                {...getFieldProps('state')}
                error={Boolean(touched.state && errors.state)}
                helpertext={touched.state && errors.state}
              >
                {
                  getOptionStates().map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helpertext={touched.city && errors.city}
            />

            <TextField
              fullWidth
              label="Postal Code"
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helpertext={touched.postalCode && errors.postalCode}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="address"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addAddress(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span className="ml-2">Add Address</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("address").value.length > 0) &&
                      getFieldProps("address").value.map((value, index) => (
                        <React.Fragment>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Address ${index + 1}`}
                            {...getFieldProps(`address.${index}`)}
                            error={Boolean(touched.address && errors.address)}
                            helpertext={touched.address && errors.address}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

CorporateForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.array,
  onSubmit: PropTypes.func,
};

CorporateForm.defaultProps = {
  initialValues: {
    corpName: "",
    contactNo: "",
    city: "",
    postalCode: "",
    country: "SG",
    state: "Singapore",
    address: [""],
  },
  apps: [],
  onSubmit: () => {}
};

export default CorporateForm;
