import React from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { 
  Stack, 
  TextField, 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

const AppForm = ({
  initialValues,
  onSubmit
}) => {
  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Vode is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              defaultValue={getFieldProps("name").value}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helpertext={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helpertext={touched.code && errors.code}
            />
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

AppForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

AppForm.defaultProps = {
  initialValues: {
    name: "",
    code: "",
  },
  onSubmit: () => {}
};

export default AppForm;
