import React, { useMemo } from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Chip,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';
import codes from "iso-language-codes"

import { countries, getStates } from "utils/common";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserForm = ({
  initialValues,
  corps,
  apps,
  onSubmit
}) => {
  const UserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    userName: Yup.string().required('Last Name is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
    email: Yup.string().required('Email is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const optionLang = useMemo(() => {
    return codes.map((code) => (
      {
        name: code.name,
        value: code.iso639_1
      }
    ))
  }, []);

  const optionCountries = countries.map((country) => (
    {
      name: country.name,
      value: country.shortName
    }
  ));

  const getOptionStates = () => {
    const options = getStates(getFieldProps("country").value);
    if (!options)
      return [];

    return options.map((value) => (
      {
        name: value,
        value: value
      }
    ))
  }

  const addAddress = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("address").value.length, "");
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helpertext={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helpertext={touched.lastName && errors.lastName}
            />

            <TextField
              fullWidth
              label="User name"
              {...getFieldProps('userName')}
              error={Boolean(touched.userName && errors.userName)}
              helpertext={touched.userName && errors.userName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="dateOfBirth"
              label="Date Of Birth"
              type="date"
              {...getFieldProps('dateOfBirth')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="National ID"
              {...getFieldProps('nationalId')}
              error={Boolean(touched.nationalId && errors.nationalId)}
              helpertext={touched.nationalId && errors.nationalId}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>App Access Controls</InputLabel>
              <Select
                labelId="appAccessControls"
                id="appAccessControls"
                multiple
                {...getFieldProps('appAccessControls')}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={apps.find(x => x.value === value)?.label} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {apps.map((app) => (
                  <MenuItem
                    key={app.label}
                    value={app.value}
                  >
                    {app.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Organizations</InputLabel>
              <Select
                labelId="organizations"
                id="organizations"
                multiple
                {...getFieldProps('organizations')}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={corps.find(x => x.value === value)?.label} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {corps.map((corp) => (
                  <MenuItem
                    key={corp.label}
                    value={corp.value}
                  >
                    {corp.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helpertext={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label="Contact No"
              {...getFieldProps('contactNo')}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helpertext={touched.contactNo && errors.contactNo}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helpertext={touched.password && errors.password}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helpertext={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Country</InputLabel>
              <Select
                label="Country"
                {...getFieldProps('country')}
                error={Boolean(touched.country && errors.country)}
                helpertext={touched.country && errors.country}
              >
                {
                  optionCountries.map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                {...getFieldProps('state')}
                error={Boolean(touched.state && errors.state)}
                helpertext={touched.state && errors.state}
              >
                {
                  getOptionStates().map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helpertext={touched.city && errors.city}
            />

            <TextField
              fullWidth
              label="Postal Code"
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helpertext={touched.postalCode && errors.postalCode}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="address"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addAddress(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span className="ml-2">Add Address</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("address").value.length > 0) &&
                      getFieldProps("address").value.map((value, index) => (
                        <React.Fragment>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Address ${index + 1}`}
                            {...getFieldProps(`address.${index}`)}
                            error={Boolean(touched.address && errors.address)}
                            helpertext={touched.address && errors.address}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Language</InputLabel>
              <Select
                label="Language"
                {...getFieldProps('language')}
                error={Boolean(touched.language && errors.language)}
                helpertext={touched.language && errors.language}
              >
                {
                  optionLang.map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormGroup>
              <InputLabel>Gender</InputLabel>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Man" />
              <FormControlLabel control={<Checkbox />} label="Woman" />
            </FormGroup>
            <FormGroup>
              <InputLabel>Role</InputLabel>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Admin" />
              <FormControlLabel control={<Checkbox />} label="User" />
            </FormGroup>
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.array,
  onSubmit: PropTypes.func,
};

UserForm.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    userName: "",
    nationalId: "",
    organizations: [],
    appAccessControls: [],
    password: "",
    confirmPassword: "",
    email: "",
    city: "",
    postalCode: "",
    gender: "man",
    country: "SG",
    stage: "Singapore",
    language: "en",
    role: "admin",
    address: [""],
  },
  apps: [],
  onSubmit: () => {}
};

export default UserForm;
