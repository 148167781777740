import axiosReq from "utils/axiosConfig";
import { alertError } from "./alert.service";

export const login = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/login`, payload);
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const loginAdmin = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/admin/login`, payload);
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}