import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createUser } from "services/adminUser.service";
import { getCorporates } from "services/adminCorporate.service";
import { getApps } from "services/adminApp.service";

import UserForm from "./UserForm";

const CreateUserForm = () => {
  const history = useHistory();
  const { isSuccess: isSuccessCorp, data: resCorp } = useQuery(["corporates"], getCorporates);
  const { isSuccess: isSuccessApp, data: resApp } = useQuery(["apps"], getApps);

  const [corps, setCorps] = useState([]);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (isSuccessCorp && resCorp) {
      setCorps(resCorp.map(item => (
        {
          value: item._id,
          label: item.corpName
        }
      )));
    }
  }, [isSuccessCorp, resCorp]);

  useEffect(() => {
    if (isSuccessApp && resApp) {
      setApps(resApp.map(item => (
        {
          value: item.code,
          label: item.name
        }
      )));
    }
  }, [isSuccessApp, resApp]);

  const submitUser = useMutation(values => {
    return createUser({values});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create User
          </Typography>
        </Stack>

        <Card>
          <UserForm 
            corps={corps}
            apps={apps}
            onSubmit={onSubmitUser}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateUserForm;