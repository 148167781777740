import React, { useCallback } from "react";
import { useHistory } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from 'components/Page';
import { LoginForm } from 'components/authentication/login';
import { APP_NAME } from "configures/setting";
// services
import { login } from "services/auth.service";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const history = useHistory();

  const onSubmit = useCallback(async (values) => {
    const res = await login({
      ...values,
      app: APP_NAME
    });
    if (res && res.status === 200) {
      const { token } = res.data;
      localStorage.setItem("accessToken", token);
      localStorage.setItem("currentUser", JSON.stringify(res.data));

      history.push("/");
    }
  }, [history]);

  return (
    <RootStyle title="Login | Minimal-UI">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }} align={"center"}>
            <Typography variant="h4" gutterBottom>
              Sign in to EProfile
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <LoginForm onSubmit={onSubmit} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
