import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getUser, updateUser } from "services/adminUser.service";
import { getCorporates } from "services/adminCorporate.service";
import { getApps } from "services/adminApp.service";

import UserForm from "./UserForm";

const EditUserForm = () => {
  const queryClient = new QueryClient();

  const { id } = useParams();
  const { isSuccess: isSuccessUser, data: resUser } = useQuery(["user-edit", { userId: id }], getUser);
  const { isSuccess: isSuccessCorp, data: resCorp } = useQuery(["corporates"], getCorporates);
  const { isSuccess: isSuccessApp, data: resApp } = useQuery(["apps"], getApps);

  const [userState, setUserState] = useState({});
  const [corps, setCorps] = useState([]);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (isSuccessUser) {
      setUserState({
        ...resUser,
        gender: resUser.gender === "" ? "man" : resUser.gender,
        country: resUser.country === "" ? "SG" : resUser.country
      });
    }
  }, [isSuccessUser, resUser]);

  useEffect(() => {
    if (isSuccessCorp) {
      setCorps(resCorp.map(item => (
        {
          value: item._id,
          label: item.corpName
        }
      )));
    }
  }, [isSuccessCorp, resCorp]);

  useEffect(() => {
    if (isSuccessApp) {
      setApps(resApp.map(item => (
        {
          value: item.code,
          label: item.name
        }
      )));
    }
  }, [isSuccessApp, resApp]);

  const submitUser = useMutation(values => {
    return updateUser({values, userId: id});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["user-edit", { userId: id }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user-edit", { userId: id }]);
      }
    });
  }

  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit User
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccessUser === true && !isEmpty(userState)) && (
              <UserForm 
                initialValues={{
                  ...userState,
                  password: "",
                  confirmPassword: "",
                }}
                corps={corps}
                apps={apps}
                onSubmit={onSubmitUser}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditUserForm;